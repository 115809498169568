import React from 'react';
import PropTypes from 'prop-types';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import {
  Row,
  Col,
  Box,
  Title,
  Text,
} from '@firsttable/web-components/atoms';
import MainMenu from '../components/organisms/Navigation/Menu';
import Footer from '../components/organisms/Footer';
import ThemeLayout from './layout';

const SideMenuLayout = ({
  title,
  subTitle,
  children,
  teamContent,
  menu,
  breadcrumbs,
}) => (
  <ThemeLayout footer={<Footer />}>
    <MainMenu />
    <ContainerContent mt={[40, 90]}>
      <Row gap={[0, 80, 120]}>
        <Col width={[1, 1 / 3]} mb={['40px', 0]}>
          <Box position="sticky" top={50} my={[null, 12]}>
            {menu}
          </Box>
        </Col>
        <Col width={[1, 2 / 3]} line hideLineOnMobile>
          <Box mb={['l', 'xl']}>
            <Title as="h1" size="h1" mb="s">
              {title}
            </Title>
            {breadcrumbs}
            {subTitle && (
              <Text letterSpacing="-0.5px" fontSize="xl">
                {subTitle}
              </Text>
            )}
          </Box>
          {children}
        </Col>
      </Row>
      {teamContent}
    </ContainerContent>
  </ThemeLayout>
);

SideMenuLayout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.any,
  teamContent: PropTypes.any,
  menu: PropTypes.node,
  breadcrumbs: PropTypes.node,
};
SideMenuLayout.defaultProps = {
  title: '',
  subTitle: '',
  children: null,
  teamContent: null,
  breadcrumbs: null,
};

export default SideMenuLayout;
