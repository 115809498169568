import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Page } from '@firsttable/types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { awsImage } from '@firsttable/functions';
import {
  Box,
  Breadcrumbs,
  Button,
  Flex,
  Link,
} from '@firsttable/web-components/atoms';
import parseHtml from '../helpers/parseHtml';
import SEO from '../components/seo';
import usePageRehydrate from '../hooks/usePageRehydrate';
import SideMenuLayout from '../layouts/SideMenuLayout';
import NavLinkComponent from '../components/atoms/NavLink';
import NavButton from '../components/atoms/NavButton';

const ImageBlock = ({
  image,
  title,
}: {
  image: string;
  title: string | undefined;
}) => {
  if (!image) {
    return null;
  }
  const cover = [awsImage(image, 350, 220), awsImage(image, 350 * 2, 220 * 2)];
  return (
    <LazyLoadImage
      srcSet={`${cover[0]} 1x, ${cover[1]} 2x`}
      src={cover[0]}
      alt={title}
      className="img-fluid"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '2px',
      }}
    />
  );
};
ImageBlock.propTypes = {
  image: PropTypes.any,
  title: PropTypes.any,
};

const IntegrationPageTemplate = ({
  data: { page },
}: {
  data: { page: Page };
}) => {
  const pageData = usePageRehydrate(page);
  const [image] = page.blocks.edges
    .filter(({ node: nodeType }: any) => nodeType.key === 'Logo')
    .map(({ node: imageBlock }: any) => JSON.parse(imageBlock.value));
  return (
    <SideMenuLayout
      title={pageData.title}
      menu={<ImageBlock image={image} title={page.menuTitle} />}
      breadcrumbs={
        <Breadcrumbs itemScope itemType="http://schema.org/BreadcrumbList">
          <li
            itemScope
            itemProp="itemListElement"
            itemType="http://schema.org/ListItem"
          >
            <Link
              NavComponent={NavLinkComponent}
              to="/integrations/"
              itemProp="item"
            >
              <Box as="span" itemProp="name">
                Integrations
              </Box>
            </Link>
            <meta itemProp="position" content="1" />
          </li>
          <li
            itemScope
            itemProp="itemListElement"
            itemType="http://schema.org/ListItem"
          >
            <Link
              NavComponent={NavLinkComponent}
              to={page.slug}
              itemProp="item"
            >
              <Box as="span" itemProp="name">
                {page.menuTitle}
              </Box>
            </Link>
            <meta itemProp="position" content="2" />
          </li>
        </Breadcrumbs>
      }
    >
      <SEO
        id={pageData.foreignId}
        title={pageData.metaTitleFormatted}
        description={pageData.metaDescription}
        ogImage={pageData.ogImage}
        metaTags={pageData.metaTags}
        slug={pageData.slug}
      />
      <Box>{parseHtml(pageData.content)}</Box>
      {/* <Divider my={['32px', '48px']} /> */}
      <Flex justifyContent="center">
        <Button
          kind="cta"
          size="l"
          mb="m"
          NavComponent={NavButton}
          to="/integrations/frequently-asked-questions/"
          my={['32px', '48px']}
        >
          Integrations FAQs
        </Button>
      </Flex>
    </SideMenuLayout>
  );
};

IntegrationPageTemplate.propTypes = {
  data: PropTypes.any,
};
IntegrationPageTemplate.defaultProps = {};

export default IntegrationPageTemplate;

export const query = graphql`
  query getIntegrationPage($slug: String!) {
    page: integration(slug: { eq: $slug }) {
      slug
      title
      parentId
      metaTitleFormatted
      metaDescription
      metaTags
      menuTitle
      id
      foreignId
      content
      className
      blocks {
        edges {
          node {
            id
            title
            key
            value
          }
        }
      }
    }
  }
`;
